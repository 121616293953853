import axios from "axios";
export default {
  async getGrn() {
    return await axios
      .get("dispatch/grn-no")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async upload() {
    return await axios
      .post("dispatch/upload-density")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async search(grno) {
    return await axios
      .get("dispatch/grn/search-for-density/" + grno)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async save(data) {
    return await axios
      .post("dispatch/save-density", { data: data })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getData() {
    return await axios
      .get(`dispatch/density-search`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async densitySearch(fromDate, toDate) {
    return await axios
      .get(`dispatch/density-search?fromDate=${fromDate}&toDate=${toDate}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
};
