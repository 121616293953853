<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-card>
        <div class="card-header-padding">
          <div class="d-flex align-center">
            <div>
              <h5 class="font-weight-bold text-h5 text-typo mb-0">Density</h5>
            </div>
          </div>
        </div>

        <!-- <v-card-text class="card-padding">
          <v-row class="mt-8">
            <v-col cols="12">
              <div>
                <v-card class="card-shadow border-radius-xl">
                  <div class="card-header-padding">
                    <div class="d-flex align-center">
                      <div>
                        <h5 class="font-weight-bold text-h5 text-typo mb-0">
                          Density
                        </h5>
                  
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-card-text> -->

        <v-card-text class="card-padding">
          <v-form ref="frmSave">
            <v-row>
              <v-col offset="10">
                <v-btn
                  @click="back"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  dark
                  color="blue"
                  class="
                    text-capitalize
                    btn-ls btn-primary
                    bg-success
                    py-3
                    px-6
                    ml-2
                  "
                  >Back</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <label
                  class="text-md text-typo font-weight-bolder ms-1 mandatory"
                  ><span class="red--text"><strong>* </strong></span>GRN</label
                >

                <v-select
                  v-model="grn"
                  :items="grns"
                  item-text="id"
                  item-value="id"
                  color="rgba(0,0,0,.6)"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mb-0
                    mt-5
                  "
                  placeholder="Select a GRN "
                  :rules="[(v) => !!v || ' GRN  is Required']"
                  outlined
                  single-line
                  height="48"
                >
                </v-select
              ></v-col>
            </v-row>
            <v-row>
              <v-col offset="8">
                <v-btn
                  color="primary"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  dark
                  class="
                    text-capitalize
                    btn-ls btn-primary
                    bg-success
                    py-3
                    px-6
                  "
                  @click="search"
                >
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <v-card class="card-shadow border-radius-xl mt-6" v-show="showTable">
        <v-card-text class="card-padding">
          <v-form ref="frmTable">
            <v-data-table
              v-model="selectedData"
              :headers="headers"
              :items="tableData"
              item-key="batch_no"
              fixed-header
              class="table"
              :footer-props="{
                'items-per-page-options': [100, 200, 500, -1],
              }"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Density</v-toolbar-title>

                  <v-spacer></v-spacer>
                  <v-btn
                    elevation="0"
                    :ripple="false"
                    height="43"
                    dark
                    @click="submit"
                    class="
                      font-weight-bold
                      text-capitalize
                      btn-ls btn-primary
                      bg-success
                      py-3
                      px-6
                      mb-5
                      mt-5
                    "
                    >Submit
                  </v-btn>
                </v-toolbar>
              </template>
              <template v-slot:[`item.density`]="{ item }">
                <v-text-field
                  v-model="item.density"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-2
                    mb-4
                    centered-input
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  :value="item.density"
                  persistent-hint
                ></v-text-field>
              </template>
            </v-data-table>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col offset-md="5"
              ><v-btn
                elevation="0"
                :ripple="false"
                height="43"
                dark
                @click="submit"
                class="
                  font-weight-bold
                  text-capitalize
                  btn-ls btn-primary
                  bg-success
                  py-3
                  px-6
                  mb-5
                "
                >Submit
              </v-btn></v-col
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import api from "../api";
export default {
  created() {
    this.initialize();
  },
  data() {
    return {
      overlay: false,
      showTable: true,
      grn: "",
      grns: [],
      file: null,
      tableData: [],
      selectedData: [],
      headers: [
        {
          text: "Batch No",
          value: "batch_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 100,
        },
        {
          text: "GRN ",
          value: "grn_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 100,
        },
        {
          text: "Density",
          value: "density",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 100,
        },
      ],
    };
  },
  methods: {
    async initialize() {
      this.grns = await api.getGrn();
    },
    async search() {
      this.overlay = true;
      this.tableData = await api.search(this.grn);
      this.selectedData = this.tableData;
      this.overlay = false;
    },
    async submit() {
      try {
        this.overlay = true;
        await api.save(this.selectedData);
        this.showSuccessAlert("Data Saved");
      } catch (error) {
        this.showErrorAlert(error);
      } finally {
        this.overlay = false;
      }
    },
    back() {
      this.$router.push({ name: "DensityUpload" });
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 1000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",
        timer: 2500,
        icon: "error",
        showConfirmButton: false,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
  },
};
</script>

<style></style>
